.chat {
	list-style: none;
	background: 0 0;
	margin: 0;
	padding: 0 0 0;
	li {
		padding: 15px;
		overflow: hidden;
		display: flex;
	}
	.avatar {
		width: 40px;
		height: 40px;
		position: relative;
		display: block;
		z-index: 2;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		background-color: $white-9;
		img {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			background-color: $white-9;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
		}
	}
	.day {
		position: relative;
		display: block;
		text-align: center;
		color: silver;
		height: 20px;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
		line-height: 38px;
		margin-top: 5px;
		margin-bottom: 20px;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
}

.msg {
	background: $white;
	min-width: 50px;
	margin: 0 10px;
	padding: 10px;
	border-radius: 2px;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
	p {
		font-size: 13px;
		margin: 0 0 .2rem 0;
		color: #313148;
	}
	img {
		position: relative;
		display: block;
		width: 450px;
		border-radius: 5px;
		box-shadow: 0 0 3px #eee;
		transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
	time {
		font-size: .7rem;
		color: #a7b4c9;
		margin-top: 3px;
		float: right;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		&:before {
			content: "\f017";
			color: #a7b4c9;
			font-family: FontAwesome;
			display: inline-block;
			margin-right: 4px;
		}
	}
}

@media screen and (max-width: 800px) {
	.msg img {
		width: 300px;
	}
}

@media screen and (max-width: 550px) {
	.msg img {
		width: 200px;
	}
}

.chat {
	outline: 0;
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 100%;
}

.chat-line {
	padding: 0;
	text-align: right;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	+.chat-line {
		padding-top: 1rem;
	}
}

.chat-message {
	position: relative;
	display: inline-block;
	background-color: $blue;
	color: $white;
	font-size: .875rem;
	padding: .375rem .5rem;
	border-radius: 3px;
	white-space: normal;
	text-align: left;
	margin: 0 .5rem 0 2.5rem;
	line-height: 1.4;
	> :last-child {
		margin-bottom: 0 !important;
	}
	&:after {
		content: "";
		position: absolute;
		right: -5px;
		top: 7px;
		border-bottom: 6px solid transparent;
		border-left: 6px solid $blue;
		border-top: 6px solid transparent;
	}
	img {
		max-width: 100%;
	}
	p {
		margin-bottom: 1em;
	}
}

.chat-line-friend {
	-ms-flex-direction: row;
	flex-direction: row;
	+.chat-line-friend {
		margin-top: -.5rem;
		.chat-author {
			visibility: hidden;
		}
		.chat-message:after {
			display: none;
		}
	}
	.chat-message {
		background-color: #f7f7fb;
		color: #3d4e67;
		margin-left: .5rem;
		margin-right: 2.5rem;
		&:after {
			right: auto;
			left: -5px;
			border-left-width: 0;
			border-right: 5px solid #f7f7fb;
		}
	}
}

#chat-message {
	i {
		padding-top: 15px;
		font-size: 16px;
	}
	&:hover {
		background: $white;
		color: #4c8aec;
		border: 2px solid #0f75ff;
	}
}

.box-shadow-0 {
	box-shadow: none !important;
}

#messages-main {
	position: relative;
	margin: 0 auto;
	&:after,
	&:before {
		content: " ";
		display: table;
	}
	.ms-menu {
		position: absolute;
		left: 0;
		top: 0;
		border-right: 1px solid #e0e5f3;
		padding-bottom: 50px;
		height: 100%;
		width: 240px;
		background: $white;
		&.toggled {
			display: block;
		}
	}
	.ms-body {
		overflow: hidden;
	}
	.ms-user>div {
		overflow: hidden;
		padding: 3px 5px 0 15px;
		font-size: 11px;
	}
	#ms-compose {
		position: fixed;
		bottom: 120px;
		z-index: 1;
		right: 30px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	}
}

#chat-message {
	color: $white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	border-radius: 50px;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	-o-border-radius: 2px;
	z-index: 10000;
	height: 50px;
	width: 50px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
}