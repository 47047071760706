$background:#ecedf7;
$color:#29334c;

/*Color variables*/

$primary1:#00148e;
$primary2:#0b04ad;
$primary3:#370090;
$primary4:#005c97;
$primary5:#0348c5;
$primary6:#095aad;
$primary7:#09838e;
$primary8:#061161;
$primary9:#2a3b84;
$primary10:#4c0161;

$secondary1:#ff8819;
$secondary2:#e91e63;
$secondary3:#e68c06;
$secondary4:#ff5919;
$secondary5:#ec296b;
$secondary6:#e67c18;

$info:#0ab2e6;
$success:#10d403;
$warning:#ffa22b;
$danger:#d60f02;
$blue:#467fcf;
$teal:#05e6e6;
$purple:#604dd8;
$gray-dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;

/*white variables*/

$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4:rgba(255, 255, 255, 0.4);
$white-5:rgba(255, 255, 255, 0.5);
$white-6:rgba(255, 255, 255, 0.6);
$white-7:rgba(255, 255, 255, 0.7);
$white-8:rgba(255, 255, 255, 0.8);
$white-9:rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/

$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);
