.timeline {
	position: relative;
	margin: 0 0 2rem;
	padding: 0;
	list-style: none;
	&:before {
		background-color: #e0e5f3;
		position: absolute;
		display: block;
		content: '';
		height: 100%;
		top: 0;
		bottom: 0;
		left: 4px;
	}
}

.timeline-item {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	padding-left: 2rem;
	margin: .5rem 0;
	&:first-child:before,
	&:last-child:before {
		content: '';
		position: absolute;
		background: $white;
		width: 1px;
		left: .25rem;
	}
	&:first-child {
		margin-top: 0;
		&:before {
			top: 0;
			height: .5rem;
		}
	}
	&:last-child {
		margin-bottom: 0;
		&:before {
			top: .5rem;
			bottom: 0;
		}
	}
}

.timeline-badge {
	position: absolute;
	display: block;
	width: .4375rem;
	height: .4375rem;
	left: 1px;
	top: .5rem;
	border-radius: 100%;
	border: 1px solid $white;
	background: #adb5bd;
}

.timeline-time {
	white-space: nowrap;
	margin-left: auto;
	color: #a7b4c9;
	font-size: 87.5%;
}

.timeline__item:after {
	background: $white !important;
}

.timeline--horizontal .timeline-divider {
	background: #dffbff !important;
}

.timeline__content {
	background-color: $white;
	border-radius: 10px;
	display: block;
	padding: 1.25rem;
	position: relative;
	background-color: #eaf0f9;
}