.user-head {
	.inbox-avatar {
		float: left;
		width: 65px;
		img {
			border-radius: 4px;
		}
	}
	.user-name {
		display: inline-block;
		margin: 0 0 0 10px;
		h5 {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 0;
			margin-top: 15px;
			a {
				color: $white;
			}
		}
		span a {
			color: #87e2e7;
			font-size: 12px;
		}
	}
}

a.mail-dropdown {
	background: none repeat scroll 0 0 #80d3d9;
	border-radius: 2px;
	color: #01a7b3;
	font-size: 10px;
	margin-top: 20px;
	padding: 3px 5px;
}

ul.inbox-nav {
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 100%;
}

.inbox-divider {
	border-bottom: 1px solid #d5d8df;
}

ul {
	&.inbox-nav li {
		display: inline-block;
		line-height: 45px;
		width: 100%;
		a {
			color: #6a6a6a;
			display: inline-block;
			line-height: 45px;
			padding: 0 20px;
			width: 100%;
			&:focus,
			&:hover {
				background: none repeat scroll 0 0 #d5d7de;
				color: #6a6a6a;
			}
		}
		&.active a {
			background: none repeat scroll 0 0 #d5d7de;
			color: #6a6a6a;
		}
		a {
			i {
				color: #6a6a6a;
				font-size: 16px;
				padding-right: 10px;
			}
			span.label {
				margin-top: 13px;
			}
		}
	}
	&.labels-info li {
		h4 {
			color: #5c5c5e;
			font-size: 13px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 5px;
			text-transform: uppercase;
		}
		margin: 0;
		a {
			border-radius: 0;
			color: #6a6a6a;
			&:focus,
			&:hover {
				background: none repeat scroll 0 0 #d5d7de;
				color: #6a6a6a;
			}
			i {
				padding-right: 10px;
			}
		}
	}
}

.inbox-head {
	border-radius: 0 4px 0 0;
	padding: 10px;
	border-radius: 3px;
	h3 {
		display: inline-block;
		font-weight: 300;
		margin: 0;
	}
	.sr-input {
		border: medium none;
		border-radius: 4px 0 0 4px;
		box-shadow: none;
		color: #8a8a8a;
		float: left;
		height: 30px;
		padding: 0 10px;
	}
	.sr-btn {
		background: none repeat scroll 0 0 #eef2f9;
		border: medium none;
		border-radius: 0 4px 4px 0;
		color: $white;
		height: 30px;
		padding: 0 20px;
	}
}

.table-inbox {
	border: 1px solid #e0e5f3;
	margin-bottom: 0;
	tr {
		border-bottom: 1px solid #e0e5f3;
		&:last-child {
			border-bottom: 0;
		}
		td {
			padding: 12px !important;
			&:hover {
				cursor: pointer;
			}
			.fa-star {
				&.inbox-started,
				&:hover {
					color: #f78a09;
				}
				color: rgba(106, 221, 196, 0.06);
			}
		}
		&.unread td {
			background: #f7f7fb;
			font-weight: 600;
			border-bottom: #e0e5f3 1px solid;
		}
	}
}

ul.inbox-pagination {
	float: right;
	li {
		float: left;
	}
}

.inbox-pagination {
	a.np-btn {
		background: none repeat scroll 0 0 #f7f7fb;
		border: 1px solid #e1ecfc;
		border-radius: 3px !important;
		color: #3d4e67;
		display: inline-block;
		padding: 5px 15px;
		margin-left: 5px;
	}
	li span {
		display: inline-block;
		margin-right: 5px;
		margin-top: 7px;
	}
}

.fileinput-button {
	background: none repeat scroll 0 0 #f7f7fb;
	border: 1px solid #e6e6e6;
}

.inbox-body .modal .modal-body {
	input,
	textarea {
		border: 1px solid #e6e6e6;
		box-shadow: none;
	}
}

.view-mail a {
	color: #ff6c60;
}

.attachment-mail {
	margin-top: 30px;
	ul {
		display: inline-block;
		margin-bottom: 30px;
		width: 100%;
		li {
			float: left;
			margin-bottom: 10px;
			margin-right: 10px;
			width: 150px;
			img {
				width: 100%;
			}
			span {
				float: right;
			}
		}
	}
	.file-name {
		float: left;
	}
	.links {
		display: inline-block;
		width: 100%;
	}
}

.fileinput-button {
	float: left;
	margin-right: 4px;
	overflow: hidden;
	position: relative;
	input {
		cursor: pointer;
		direction: ltr;
		font-size: 23px;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-300px, 0) scale(4);
	}
}

.mail-chats {
	height: 100%;
	min-height: 0;
	border-top: 1px solid #e0e5f3;
	margin-top: 10px;
	padding: 10px 50px 10px 0;
	width: 100%;
	list-style-type: none;
	flex-direction: column !important;
	display: flex !important;
	li.chat-persons {
		padding: 10px;
		display: block;
	}
}

li.chat-persons a {
	text-decoration: none;
}

.mail-chats li.chat-persons a {
	span.pro-pic {
		img {
			max-width: 100%;
			width: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			border-radius: 100%;
			flex-direction: column !important;
		}
		display: inline-block;
		padding: 0;
		width: 20%;
		max-width: 40px;
		float: left;
		margin-right: 20px;
	}
	div.user {
		flex-direction: column !important;
	}
}

ul.mail-chats li.chat-persons a div.user {
	display: flex !important;
}

.mail-chats li.chat-persons.user {
	width: 80%;
	padding: 5px 10px 0 15px;
	flex-direction: column !important;
	display: flex !important;
}

.sender-info {
	margin-bottom: 20px;
	img {
		height: 30px;
		width: 30px;
	}
}

.sender-dropdown {
	background: none repeat scroll 0 0 #e0e5f3;
	color: #777;
	font-size: 10px;
	padding: 0 3px;
}

.fileupload-buttonbar {
	.btn,
	.toggle {
		margin-bottom: 5px;
	}
}

.files .progress {
	width: 200px;
}

.fileupload-processing .fileupload-loading {
	display: block;
}

* html .fileinput-button {
	line-height: 24px;
	margin: 1px -3px 0 0;
}

@media (max-width: 767px) {
	.files {
		.btn span {
			display: none;
		}
		.preview * {
			width: 40px;
		}
		.name * {
			display: inline-block;
			width: 80px;
			word-wrap: break-word;
		}
		.progress {
			width: 20px;
		}
		.delete {
			width: 60px;
		}
	}
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.user p {
	&.u-name {
		margin: 0;
		font-size: 14px;
		font-weight: 600;
		line-height: 18px;
		color: #a7b4c9;
	}
	&.u-designation {
		margin: 0;
		font-size: 11px;
		color: #a7b4c9;
	}
}

div.online-status .status.online {
	background: rgba(0, 128, 0, 0.68);
}

.online-status .status {
	&.offline {
		background: rgba(255, 0, 0, 0.7);
	}
	height: 10px;
	width: 10px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	border-radius: 100%;
	display: inline-flex;
	justify-content: flex-start;
	transform: translateX(-60px) translateY(2px);
}

.online {
	margin-left: 20px;
	margin-top: -3px;
}

.message-feed {
	padding: 10px;
}

#footer,
.fc-toolbar .ui-button,
.fileinput .thumbnail {
	text-align: center;
}

.four-zero {
	text-align: center;
	footer>a {
		text-align: center;
	}
}

.ie-warning,
.login-content,
.login-navigation {
	text-align: center;
}

.pt-inner {
	text-align: center;
	.pti-footer>a {
		text-align: center;
	}
}

.message-feed.right {
	>.pull-right {
		margin-left: 15px;
	}
	.mf-content {
		background: #f7f7fb;
	}
}

.mf-content {
	padding: 9px;
	border-radius: 2px;
	display: inline-block;
	max-width: 80%;
	position: relative;
}

.message-feed {
	&:not(.right) .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		left: -20px;
		top: 11px;
	}
	&.right .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-left-color: #f7f7fb;
		right: -20px;
		top: 11px;
	}
	&.media .media-body,
	&.right .media-body {
		overflow: visible;
	}
}

.inbox-message {
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			position: relative;
			padding: 15px 20px;
			border-bottom: 1px solid #e0e5f3;
			&:focus,
			&:hover {
				background: #f7f7fb;
			}
		}
	}
	.message-avatar {
		position: absolute;
		left: 30px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.message-avatar img {
	display: inline-block;
	width: 54px;
	height: 54px;
	border-radius: 50%;
}

.inbox-message .message-body {
	margin-left: 85px;
	font-size: 15px;
	color: #a7b4c9;
}

.message-body-heading h5 {
	font-weight: 600;
	display: inline-block;
	color: #a7b4c9;
	margin: 0 0 7px 0;
	padding: 0;
}

.message-body h5 span {
	border-radius: 50px;
	line-height: 14px;
	font-size: 12px;
	color: $white;
	font-style: normal;
	padding: 4px 10px;
	margin-left: 5px;
	margin-top: -5px;
	&.unread {
		background: #07b107;
	}
	&.important {
		background: #dd2027;
	}
	&.pending {
		background: #2196f3;
	}
}

.message-body-heading span {
	float: right;
	font-size: 14px;
}

.messages-inbox .message-body p {
	margin: 0;
	padding: 0;
	line-height: 27px;
	font-size: 15px;
}

@media (max-width: 320px) {
	.perfect-datetimepicker {
		font-size: 12px !important;
	}
}

@media (max-width: 600px) {
	.job-box-filter label {
		width: 100%;
		text-align: center;
	}
	.message-body h5 span {
		&.pending,
		&.unread {
			display: none;
		}
	}
}

#index-video {
	.index-video-container {
		position: relative;
		overflow: hidden;
		&:after {
			bottom: 0;
			content: "";
			left: 0;
			opacity: .7;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		.videocaption {
			left: 1%;
			position: absolute;
			right: 1%;
			text-align: center;
			top: 30%;
			z-index: 2;
			h2 {
				color: $white;
				font-size: 4.5em;
				font-weight: 900;
				-webkit-box-sizing: content-box;
				-moz-box-sizing: content-box;
				box-sizing: content-box;
				border: none;
				color: rgba(255, 255, 255, 1);
				text-align: center;
				-o-text-overflow: clip;
				text-overflow: clip;
				text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.0980392), 0 0 5px rgba(0, 0, 0, 0.0980392), 0 1px 3px rgba(0, 0, 0, 0.298039), 0 3px 5px $black-2, 0 5px 10px rgba(0, 0, 0, 0.247059), 0 10px 10px $black-2, 0 20px 20px rgba(0, 0, 0, 0.14902);
				-webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-moz-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
			}
			h4 {
				color: $white;
				font-size: 2em;
				margin-top: 25px;
			}
			.btn-slide {
				background: #f62459;
				color: $white;
				font-size: 16px;
				font-weight: 300;
				margin: 25px auto 10px auto;
				padding: 20px 50px;
				border-radius: 0;
				text-transform: uppercase;
				&:hover {
					background: $white;
					color: #333;
				}
			}
		}
	}
	video {
		width: 100%;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
}

@media (min-width: 569px) and (max-width: 992px) {
	#index-video .index-video-container .videocaption {
		top: 33%;
	}
	.item-card-img h3.text-white {
		font-size: 1rem;
	}
	.item-card2 h4.bg-light.p-3 {
		font-size: .8rem;
	}
}

@media (max-width: 448px) {
	#index-video .index-video-container .videocaption {
		top: 12%;
	}
}

@media (max-width: 568px) and (min-width: 480px) {
	.container-messages {
		position: absolute;
		top: 50px !important;
	}
	.messages-list {
		top: 24px !important;
		bottom: 73px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 71px !important;
	}
	.messages-left-footer {
		bottom: 40px !important;
		display: none;
	}
	.messages-left .card-header {
		display: none;
	}
	.message-footer,
	.message-header {
		height: 50px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
}

@media (max-width: 576px) and (min-width: 475px) {
	#block2 {
		position: absolute;
		top: -70px;
	}
}

@media (max-width: 476px) and (min-width: 321px) {
	#block2 {
		position: absolute;
		top: -70px;
	}
}

@media (max-width: 990px) and (min-width: 569px) {
	.message-header,
	.message-footer {
		height: 50px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 80px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
	.messages-left .card-header {
		display: none;
	}
	.messages-list {
		top: 24px !important;
	}
}

.mail-option {
	display: inline-block;
	margin-bottom: 10px;
	width: 100%;
	.btn-group,
	.chk-all {
		margin-right: 5px;
	}
	.btn-group a.btn {
		background: none repeat scroll 0 0 #f7f7fb;
		border: 1px solid #e1ecfc;
		border-radius: 3px !important;
		color: #3d4e67;
		display: inline-block;
		padding: 5px 10px;
	}
	.chk-all {
		background: none repeat scroll 0 0 #f7f7fb;
		border: 1px solid #e1ecfc;
		border-radius: 3px !important;
		color: #3d4e67;
		display: inline-block;
		padding: 5px 10px;
		input[type=checkbox] {
			margin-top: 0;
		}
	}
	.btn-group a.all {
		border: medium none;
		padding: 0;
	}
}