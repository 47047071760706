.header-main .social-icons {
	float: right;
	li {
		color: #3f3e50;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #3f3e50;
		}
	}
}

.header-search {
	padding: 1.5rem 1.5rem;
	background: $white;
	.header-icons {
		.header-icons-link {
			display: flex;
			margin-bottom: 0;
			li a {
				background: $white;
				width: 40px;
				height: 40px;
				border-radius: 50px;
				text-align: center;
				line-height: 2;
				margin-right: 5px;
				display: block;
				border: 0px solid #e0e5f3;
				color: #3f3e50;
				font-size: 20px !important;
			}
			.header-icons-link1,
			.header-icons-link2 {
				width: 2.5rem;
				text-align: center;
				height: 2.5rem;
				font-size: 16px;
				position: relative;
			}
		}
		.header-icons-link1 .main-badge1 {
			position: absolute;
			top: -3px;
			right: -8px;
			text-align: center;
			font-size: 10px;
			padding: 0.2rem 0.32rem !important;
		}
		.header-icons-link.icons li {
			background: $white;
		}
	}
	.header-inputs .input-group-append.searchicon {
		top: 10px;
		right: 25px;
		position: relative;
	}
	.header-search-logo {
		margin-right: 1rem;
	}
	.header-nav .nav-cart .icon-cart {
		i {
			font-size: 18px;
			color: #a7b4c9;
		}
		a {
			color: #a7b4c9;
		}
	}
}

.header-nav {
	display: flex;
}

.header-links {
	padding: 3px;
	li {
		margin: 5px;
		a {
			padding: 9px 18px;
			border-radius: 3px;
		}
	}
}

.header-main {
	.top-bar .contact {
		margin: 9px 0;
		padding-left: 15px;
		li {
			margin: 0;
		}
	}
	.top-bar-right .custom {
		display: flex;
		margin: 10px 0;
		li {
			margin-right: 15px;
			font-size: 13px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.ace-responsive-menu {
	margin-right: 1rem;
}

.header-main {
	.ace-responsive-menu li a {
		color: #3f3e50;
		&:hover {
			color: $white;
		}
		&:focus {
			color: $black;
		}
	}
	.post-btn {
		float: right;
		margin-left: auto;
	}
}

.header-menu1 {
	float: none !important;
}

body.fixed-header .page {
	padding-top: 4.5rem;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-right: 22rem;
	}
}

.content-heading {
	font-weight: 400;
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;
	&:first-child {
		margin-top: 0;
	}
}

.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid #e0e5f3;
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

@media (min-width: 992px) and (max-width: 1350px) {
	.header-menu1 .input-group {
		width: 140px;
	}
}

.header-main-banner {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	z-index: 99;
}

.header-text1 {
	.form-control {
		border: 1px solid $white;
	}
	.select2-container--default .select2-selection--single {
		border: 1px solid $white !important;
	}
}

.header-main-banner {
	.horizontal-main,
	.horizontalMenu>.horizontalMenu-list {
		background: 0 0;
	}
}

@media print {
	.header {
		display: none;
	}
}

.heading-inverse {
	background-color: #333;
	color: $white;
	padding: 5px;
}

.heading-secondary {
	color: $white;
	padding: 5px;
}

.heading-success {
	background-color: $success;
	color: $white;
	padding: 5px;
}

.heading-info {
	background-color: #00d6e6;
	color: $white;
	padding: 5px;
}

.heading-warning {
	background-color: $warning;
	color: $white;
	padding: 5px;
}

.heading-danger {
	background-color: $danger;
	color: $white;
	padding: 5px;
}

.header {
	.dropdown-menu {
		margin-top: 0;
	}
	.mega-menu {
		width: 350px;
	}
}

.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}

.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
	.header-brand-img {
		margin-right: 0;
	}
}

.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 0;
	min-width: 190px;
	margin-top: -1.5px;
	margin-left: 17px;
	.header-brand-img {
		margin-right: 0;
	}
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: .5rem;
	width: auto;
}

.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
}

.center-block {
	margin-right: auto;
	margin-left: auto;
	float: inherit !important;
}

.header-btn.has-new {
	position: relative;
	&:before {
		content: '';
		width: 6px;
		height: 6px;
		position: absolute;
		top: 4px;
		right: 4px;
		border-radius: 50%;
	}
}

.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: $white;
	&:hover {
		color: $white;
	}
}

.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 3px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

.header {
	.nav-item .badge,
	.nav-link .badge {
		position: absolute;
		top: 0;
		right: -1px;
		padding: .2rem .25rem;
		min-width: 1rem;
		font-size: 13px;
	}
}

#headerMenuCollapse {
	.nav-item .badge,
	.nav-link .badge {
		position: relative;
		min-width: 1rem;
		font-size: 10px;
		font-weight: 500;
	}
}

.header {
	padding-top: .75rem;
	padding-bottom: .75rem;
	width: 100%;
	z-index: 100;
}

.heading-inbox h4 {
	border-bottom: 1px solid #ddd;
	color: #444;
	font-size: 18px;
	margin-top: 20px;
	padding-bottom: 10px;
}

.map-header {
	height: 15rem;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 10rem;
		background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, #f7f7fb 95%);
		pointer-events: none;
	}
}

.map-header-layer {
	height: 100%;
}

.map-static {
	height: 120px;
	width: 100%;
	max-width: 640px;
	background-position: center center;
	background-size: 640px 120px;
}

@-webkit-keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

@keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

.header-style .horizontalMenu {
	float: inherit !important;
}


/* Header styles*/

.header-style03 {
	.sticky-wrapper {
		position: relative;
	}
	.horizontal-main {
		background: $white;
	}
	.horizontalMenu>.horizontalMenu-list>li>a {
		color: #3f3e50;
		>.fa {
			color: #797896;
		}
		&.active .fa {
			color: rgb(255, 255, 255);
		}
	}
}

.sticky-wrapper.is-sticky .horizontal-main.header-style-04 {
	background: $white !important;
}

.header-transparent {
	background: rgba(255, 255, 255, 0.15);
}

.sticky-wrapper.is-sticky .header-transparent {
	background: $white;
}

.app-header-background .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: rgb(255, 255, 255) !important;
}

.header-main {
	.top-bar.app-header-background .contact.border-left {
		border-left: 1px solid $white-2 !important;
	}
	.app-header-background .select2-container--default .select2-selection--single .select2-selection__arrow b {
		border-color: rgb(255, 255, 255) transparent transparent transparent;
	}
}

.header-absolute {
	position: absolute;
	width: 100%;
	z-index: 99;
	.top-bar {
		background: $black-3;
		border-bottom: 1px solid $white-1;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		color: $white-8 !important;
	}
}

.header-main.header-absolute {
	.top-bar .contact.border-left {
		border-left: 1px solid $white-2 !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__arrow b {
		border-color: $white-5 transparent transparent transparent;
	}
}